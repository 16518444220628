<template>
  <div>
    <div class="pc_modern">
      <div class="m-banner-box">
        <div class="m-banner-boxs">
          <div class="banner-name">现代医院绩效管理体系建设</div>
          <div class="banner-little-name">
            以预算为基础，工作量（DRGs&DIP）为依据，以绩效管理为抓手-促进医院“提质增效”
          </div>
          <div class="banner-bnt" @click="openPDF">下载资料</div>
        </div>
      </div>
      <div class="modern_bg">
        <!-- 核心内容 -->
        <projectMod :projectData="projectData" />
        <!-- 方案特点及实施效果 -->
        <implementationMod
          :implementationData="implementationData"
          @relationUs="relationUs"
        />
        <!-- 方案设计原则 -->
        <div class="principleDiv" v-scroll-reveal>
          <div class="flex title2" v-scroll-reveal>
            <img class="titleIcon1" src="../assets/img/titleIcon1.png" />
            方案设计原则
            <img
              class="titleIcon1 rotate180"
              src="../assets/img/titleIcon1.png"
            />
          </div>
          <div class="flex mes2" v-scroll-reveal>
            <img src="../assets/img/titleIcon2.png" />
          </div>
          <div class="principle">
            <div class="tt tt1">挖潜力</div>
            <div class="tt tt2">提效率</div>
            <div class="tt tt3">调结构</div>
            <div class="tt tt4">控成本</div>
            <div class="tt tt5">促增长</div>
            <div class="pt1">
              <div>提高床位使用率</div>
              <div>提高百元医疗设备产值</div>
              <div>提高CMI值</div>
              <div>打造专科优势病种</div>
            </div>
            <div class="pt2">
              <div>缩短平均住院日</div>
              <div>单床日产值</div>
              <div>人均产值</div>
            </div>
            <div class="pt3">
              <div>增加医疗服务收入</div>
              <div>增加预防保健收入</div>
              <div>增加检查手术收入</div>
            </div>
            <div class="pt4">
              <div>药品成本、耗材成本</div>
              <div>固定人员成本</div>
              <div>运营成本</div>
              <div>病种次均出院费用</div>
            </div>
            <div class="pt5">
              <div>增加门诊量和住院量</div>
              <div>医院有效收入总量</div>
              <div>职工绩效总量</div>
            </div>
          </div>
        </div>
      </div>
      <cycleMod :cycleData="cycleData" />
      <serviceAcc :bg="1" />
      <expertItemMod />
      <relation :open="open" @openFun="openFun" />
    </div>
    <mobileModern
      class="mobile_modern"
      :projectData="projectData"
      :implementationData="implementationData"
    />
  </div>
  
</template>

<script>
import projectMod from "../components/projectMod";
import implementationMod from "../components/implementationMod";
import cycleMod from "../components/cycleMod";
import serviceAcc from "../components/serviceAcc";
import expertItemMod from "../components/expertItemMod";
import relation from "../components/relation";
import mobileModern from '@/mobileViews/modern.vue'
export default {
  components: {
    projectMod,
    implementationMod,
    cycleMod,
    serviceAcc,
    expertItemMod,
    relation,
    mobileModern
  },
  data() {
    return {
      open: false,
      projectData: {
        title: "核心内容",
        fontWeight: "bold",
        list: [
          {
            img: require("../assets/img/modernImg1.png"),
            name: "01",
            introduce: "医院绩效与经营评估",
          },
          {
            img: require("../assets/img/modernImg2.png"),
            name: "02",
            introduce: "科室经济核算及成本控制",
          },
          {
            img: require("../assets/img/modernImg3.png"),
            name: "03",
            introduce: "医院绩效一次方案设计与实施",
          },
          {
            img: require("../assets/img/modernImg4.png"),
            name: "04",
            introduce: "科室绩效二次方案设计与实施",
          },
          {
            img: require("../assets/img/modernImg5.png"),
            name: "05",
            introduce: "绩效考核体系设计与实施",
          },
          {
            img: require("../assets/img/modernImg6.png"),
            name: "06",
            introduce: "医院绩效培训与优化调整",
          },
        ],
      },
      implementationData: {
        title: "方案特点及实施效果",
        isBnt: true,
        list: [
          {
            img: require("../assets/img/modernImg7.png"),
            name: "精准定制",
            introduce:
              "符合DIP/DRGs新医保的支付方式，医院的激励政策更具有针对性和导向性。",
          },
          {
            img: require("../assets/img/modernImg8.png"),
            name: "成本控制",
            introduce:
              "药品和耗材成本得到控制，医院收支出结构得到明显优化，促进医院可持续发展。",
          },
          {
            img: require("../assets/img/modernImg9.png"),
            name: "减少患者负担",
            introduce:
              "门诊次均费用和住院均次费用得到有效控制，不增加患者负担。",
          },
          {
            img: require("../assets/img/modernImg10.png"),
            name: "提升员工收益",
            introduce:
              "员工的绩效工资显著增加，绩效工资占员工收入的比重加大，激励性增强。",
          },
          {
            img: require("../assets/img/modernImg11.png"),
            name: "合理分配",
            introduce:
              "体现了向临床线人员倾斜，绩效工资分配实现多劳多得，调动了医务人员积极性。",
          },
          {
            img: require("../assets/img/modernImg12.png"),
            name: "全方面发展",
            introduce:
              "医院业务量增加，人均效率值提高，优势病种和专科得到倾斜和发展。",
          },
        ],
      },
      cycleData: {
        title: "项目完成周期",
        introduce: "签订合同6个月完成",
        list: [
          "咨询",
          "签约",
          "启动医院绩效管理体系建设项目",
          "多维度培训赋能",
          "结项",
        ],
        list2: [
          { cycle: "第一个月", introduce: "医院运营及绩效调研评估" },
          { cycle: "第二个月", introduce: "绩效培训及方案设计" },
          { cycle: "第三个月", introduce: "绩效数据测算及绩效培训" },
          { cycle: "第四-五个月", introduce: "试运营辅导" },
          { cycle: "第六个月", introduce: "召开职代会" },
        ],
      },
    };
  },
  methods: {
    /** 打开弹窗-组件内通知打开 */
    relationUs(bo) {
      this.open = bo;
    },
    /** 提交后关闭 */
    openFun(bo) {
      this.open = bo;
    },
    /** 下载资料 */
    openPDF() {
      window.open(
        "https://yice-prod.oss-cn-guangzhou.aliyuncs.com/other/protalDown/%E7%8E%B0%E4%BB%A3%E5%8C%BB%E9%99%A2%E7%BB%A9%E6%95%88%E7%AE%A1%E7%90%86%E4%BD%93%E7%B3%BB%E5%BB%BA%E8%AE%BE%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88.pdf"
      );
    },
  },
};
</script>

<style lang="scss">
.m-banner-box {
  width: 100%;
  height: 400px;
  background-image: url("../assets/img/system.png");
  background-size: 100%;
  background-repeat: no-repeat;
  // background-position: 0 -181px;
  .m-banner-boxs {
    width: 1200px;
    margin: 0 auto;

    .banner-name {
      font-size: 52px;
      font-weight: 400;
      color: #fff;
      padding-top: 92px;
    }
    .banner-little-name {
      margin: 30px 0 50px 0;
      color: white;
    }
    .banner-bnt {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #2d43f2;
      border-radius: 50px;
      color: #fff;
      font-size: 16px;
      margin-top: 48px;
      cursor: pointer;
    }
    .banner-bnt:hover {
      background: #4273ff;
    }
  }
}
.content-box {
  width: 1200px;
}
.modern_bg {
  background-image: url("../assets/img/plan_project_bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
}
.principleDiv {
  background-image: url("../assets/img/core_advantage_bg2.png");
  background-position: left bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-bottom: 270px;
  // margin-bottom: 20px;
  .title2 {
    margin-top: 120px;
  }
  .principle {
    width: 1200px;
    height: 328px;
    margin: 128px auto 0;
    background-image: url("../assets/img/modernImg13.png");
    background-size: 100%;
    background-repeat: no-repeat;
    position: relative;
    font-size: 16px;
    color: #333333;
    // line-height: 30px;
    .tt {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
    }
    .tt1 {
      position: absolute;
      z-index: 10;
      left: 64px;
      top: 150px;
    }
    .tt2 {
      position: absolute;
      z-index: 10;
      left: 317px;
      top: 231px;
    }
    .tt3 {
      position: absolute;
      z-index: 10;
      left: 574px;
      top: 150px;
    }
    .tt4 {
      position: absolute;
      z-index: 10;
      left: 825px;
      top: 231px;
    }
    .tt5 {
      position: absolute;
      z-index: 10;
      left: 1079px;
      top: 150px;
    }
    .pt1 {
      position: absolute;
      z-index: 10;
      left: 42px;
      bottom: -66px;
      line-height: 30px;
    }
    .pt2 {
      position: absolute;
      z-index: 10;
      left: 282px;
      top: -7px;
      line-height: 30px;
    }
    .pt3 {
      position: absolute;
      z-index: 10;
      left: 547px;
      bottom: -39px;
      line-height: 30px;
    }
    .pt4 {
      position: absolute;
      z-index: 10;
      left: 784px;
      top: 1px;
      line-height: 30px;
    }
    .pt5 {
      position: absolute;
      z-index: 10;
      left: 1045px;
      bottom: -39px;
      line-height: 30px;
    }
  }
}

.mobile_modern {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_modern {
    display: block;
    background-color: #fff;
  }
  .pc_modern {
    display: none;
  }
}
</style>
