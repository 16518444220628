<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">现代医院绩效管理体系建设</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">以预算为基础，工作量（DRGs&DIP）为依据<br />以绩效管理为抓手-促进医院“提质增效”</div>
    </div>
    <div class="modern_m_box">
      <div class="modern_m_kernel">
        <div class="index_title">
          <span class="index_title_before"></span>
          <span class="index_title_span">{{ projectData.title }}</span>
          <span class="index_title_after"></span>
        </div>
        <div class="modern_m_kernel_list">
          <div class="modern_m_kernel_item" v-for="(item, index) in projectData.list" :key="index">
            <img class="modern_m_kernel_img" :src="item.img" alt="">
            <div class="modern_m_kernel_name">{{ item.name }}</div>
            <div class="modern_m_kernel_introduce">{{ item.introduce }}</div>
          </div>
        </div>
      </div>
      <div class="modern_m_trait">
        <div class="index_title">
          <span class="index_title_before"></span>
          <span class="index_title_span">{{ implementationData.title }}</span>
          <span class="index_title_after"></span>
        </div>
        <div class="modern_m_trait_list">
          <div class="modern_m_trait_item" v-for="(item, index) in implementationData.list" :key="index">
            <img class="modern_m_trait_img" :src="item.img" alt="">
            <div class="modern_m_trait_i">
              <div class="modern_m_trait_name">{{ item.name }}</div>
              <div class="modern_m_trait_introduce">{{ item.introduce }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modern_m_principle">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">方案设计原则</span>
        <span class="index_title_after"></span>
      </div>
      <div class="modern_m_principle_list">
        <div class="modern_m_principle_item">
          <div class="modern_m_principle_left">
            <img class="modern_m_principle_img" src="../assets/img/principle_bg1.png" alt="">
            <div class="modern_m_principle_index">01</div>
          </div>
          <div class="modern_m_principle_right">
            <div class="modern_m_principle_name">
              <img src="../assets/img/principle_m5.png" alt="">
              挖潜力
            </div>
            <div class="modern_m_principle_introduce">
              提高床位使用率<br />
              提高百元医疗设备产值<br />
              提高CMI值<br />
              打造专科优势病种
            </div>
          </div>
        </div>
        <div class="modern_m_principle_item">
          <div class="modern_m_principle_right">
            <div class="modern_m_principle_name">
              <img src="../assets/img/principle_m3.png" alt="">
              提效率
            </div>
            <div class="modern_m_principle_introduce">
              缩短平均住院日<br />
              单床日产值<br />
              人均产值
            </div>
          </div>
          <div class="modern_m_principle_left">
            <img class="modern_m_principle_img" src="../assets/img/principle_bg3.png" alt="">
            <div class="modern_m_principle_index">02</div>
          </div>
        </div>
        <div class="modern_m_principle_item">
          <div class="modern_m_principle_left">
            <img class="modern_m_principle_img" src="../assets/img/principle_bg4.png" alt="">
            <div class="modern_m_principle_index">03</div>
          </div>
          <div class="modern_m_principle_right">
            <div class="modern_m_principle_name">
              <img src="../assets/img/principle_m4.png" alt="">
              调结构
            </div>
            <div class="modern_m_principle_introduce">
              增加医疗服务收入<br />
              增加预防保健收入<br />
              增加检查手术收入
            </div>
          </div>
        </div>
        <div class="modern_m_principle_item">
          <div class="modern_m_principle_right">
            <div class="modern_m_principle_name">
              <img src="../assets/img/principle_m2.png" alt="">
              控成本
            </div>
            <div class="modern_m_principle_introduce">
              药品成本、耗材成本<br />
              固定人员成本<br />
              运营成本<br />
              病种次均出院费用
            </div>
          </div>
          <div class="modern_m_principle_left">
            <img class="modern_m_principle_img" src="../assets/img/principle_bg2.png" alt="">
            <div class="modern_m_principle_index">04</div>
          </div>
        </div>
        <div class="modern_m_principle_item">
          <div class="modern_m_principle_left">
            <img class="modern_m_principle_img" src="../assets/img/principle_bg5.png" alt="">
            <div class="modern_m_principle_index">05</div>
          </div>
          <div class="modern_m_principle_right">
            <div class="modern_m_principle_name">
              <img src="../assets/img/principle_m1.png" alt="">
              促增长
            </div>
            <div class="modern_m_principle_introduce">
              增加门诊量和住院量<br />
              医院有效收入总量<br />
              职工绩效总量
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modern_m_period">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">项目完成周期</span>
        <span class="index_title_after"></span>
      </div>
      <div class="modern_m_period_list">
        <div class="modern_m_period_item">
          <div class="modern_m_period_month">第一个月</div>
          <div class="modern_m_period_title">医院运营及绩效<br />调研评估</div>
        </div>
        <div class="modern_m_period_item">
          <div class="modern_m_period_month">第二个月</div>
          <div class="modern_m_period_title">绩效培训及<br />方案设计</div>
        </div>
        <div class="modern_m_period_item">
          <div class="modern_m_period_month">第三个月</div>
          <div class="modern_m_period_title">绩效数据测算及<br />绩效培训</div>
        </div>
        <div class="modern_m_period_item">
          <div class="modern_m_period_month">第四-五个月</div>
          <div class="modern_m_period_title">试运营辅导</div>
        </div>
        <div class="modern_m_period_item">
          <div class="modern_m_period_month">第六个月</div>
          <div class="modern_m_period_title">召开职代会</div>
        </div>
        <img class="modern_m_period_img" src="../assets/img/period_m_img.png" alt="">
      </div>
      <div class="modern_m_period_ii">启动医院绩效管理体系建设项目</div>
    </div>
    <div class="serve_m_box">
      <div class="index_title">
        <span class="index_title_before"></span>
        <span class="index_title_span">服务案例</span>
        <span class="index_title_after"></span>
      </div>
      <div class="index_subhead">易策医管集团已累计为超过<span style="color: #2D43F2;font-weight: 500">800+</span>家医院提供优质服务</div>
      <div class="serve_m_type">
        <div
          :class="['serve_m_type_item', isType == item.type ? 'serve_m_type_isItem' : '']"
          v-for="(item, index) in hisTypeList"
          :key="index" 
          @click="changeTab(item.type)"
        >
          {{ item.value }}
        </div>
      </div>
      <div class="serve_m_hospital">
        <div class="serve_m_hospital_item" v-for="(item, index) in hisList2" :key="index" @click="getDetails(item.hospitalId)">
          <img class="serve_m_hospital_img" :src="item.hospitalLogo" />
          <div class="serve_m_hospital_name">{{ item.hospitalName }}</div>
        </div>
      </div>
      <div class="index_more" @click="stretch">{{ hisShow ? '收起' : '查看更多' }}</div>
    </div>
  </div>
</template>

<script>
  import { getHospitalList } from "@/api/index";
  export default {
    props: ['projectData', 'implementationData'],
    data() {
      return {
        isType: null,
        hisShow: false,
        hisTypeList: [
          { type: null, value: "全部" },
          { type: "综合医院", value: "综合医院" },
          { type: "中医医院", value: "中医医院" },
          { type: "妇幼保健院", value: "妇幼保健院" },
          { type: "其他类型", value: "其他类型" },
        ],
        hisList2: []
      }
    },
    created() {
      this.changeTab(null)
    },
    methods: {
      changeTab(i) {
        this.isType = i;
        let data = {
          // hospitalProvince: this.HisTilter,
          hospitalType: i,
        };
        getHospitalList(data).then((res) => {
          if (res.code == 200) {
            this.hisList2 = res.rows.slice(0, 10);
            this.hisListCopy = JSON.parse(JSON.stringify(res.rows))
            this.hisShow = false
          }
        });
      },
      stretch(){
        this.hisShow = !this.hisShow
        if(this.hisShow == false){
          this.hisList2 = this.hisListCopy.slice(0, 10)
        }else{
          this.hisList2 = this.hisListCopy
        }
      },
      getDetails(hospitalId) {
        this.$router.push({
          path: "/serve",
          query: { activeName:2, hospitalId: hospitalId },
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg6.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 42px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 26px;
      color: #fff;
      text-align: center;
    }
  }
  .index_title {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin: 0 auto;
    .index_title_span {
      margin: 0 20px;
    }
    .index_title_after {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
    .index_title_before {
      display: inline-block;
      width: 107px;
      height: 23px;
      background-image: url("../assets/img/titleIcon1.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .modern_m_box {
    padding: 45px 37px 0 37px;
    .modern_m_kernel {
      .modern_m_kernel_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 45px 0;
        .modern_m_kernel_item {
          width: 328px;
          text-align: center;
          background: #FFFFFF;
          box-shadow: 0px 3px 10px 1px rgba(146,146,146,0.16);
          border: 1px solid #E1E1E1;
          padding: 29px 0;
          margin-bottom: 30px;
          .modern_m_kernel_img {
            width: 62px;
          }
          .modern_m_kernel_name {
            font-size: 24px;
            font-weight: bold;
            color: #333333;
            padding: 15px 0;
          }
          .modern_m_kernel_introduce {
            font-size: 20px;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
    .modern_m_trait {
      .modern_m_trait_list {
        padding: 43px 0;
        .modern_m_trait_item {
          display: flex;
          align-items: center;
          margin-bottom: 31px;
          .modern_m_trait_img {
            width: 91px;
            height: 91px;
          }
          .modern_m_trait_i {
            margin-left: 48px;
            .modern_m_trait_name {
              font-size: 26px;
              font-weight: bold;
              color: #1E1E1E;
              margin-bottom: 11px;
            }
            .modern_m_trait_introduce {
              font-size: 26px;
              font-weight: 400;
              color: #666666;
              line-height: 39px;
            }
          }
        }
      }
    }
  }
  .modern_m_principle {
    padding: 45px 80px 0 80px;
    .modern_m_principle_list {
      .modern_m_principle_item {
        display: flex;
        align-items: center;
        margin: 60px 0;
        .modern_m_principle_left {
          position: relative;
          .modern_m_principle_img {
            width: 192px;
            height: 205px;
          }
          .modern_m_principle_index {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 53px;
            font-weight: bold;
            color: #FFFFFF;
            width: 192px;
            height: 205px;
            line-height: 205px;
            text-align: center;
            z-index: 2;
          }
          &:nth-child(odd) {
            margin-right: 30px;
          }
          &:nth-child(even) {
            margin-left: 30px;
          }
        }
        .modern_m_principle_right {
          width: calc(100% - 222px);
          padding-left: 40px;
          .modern_m_principle_name {
            font-size: 30px;
            font-weight: bold;
            color: #333333;
            margin-bottom: 14px;
            img {
              width: 25px;
              height: 25px;
              margin-left: -30px;
            }
          }
          .modern_m_principle_introduce {
            font-size: 28px;
            font-weight: 400;
            color: #333333;
            line-height: 42px;
          }
        }
      }
    }
  }
  .modern_m_period {
    background-color: #F5F6F8;
    padding: 45px 37px 0 37px;
    background-image: url("../assets/img/cycle_bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .modern_m_period_list {
      position: relative;
      height: 500px;
      .modern_m_period_img {
        width: 336px;
        height: 259px;
        position: absolute;
        top: 200px;
        left: 170px;
      }
      .modern_m_period_item {
        position: absolute;
        z-index: 2;
        .modern_m_period_month {
          font-size: 24px;
          font-weight: bold;
          color: #1E61FF;
          margin-bottom: 16px;
        }
        .modern_m_period_title {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
        }
        &:nth-child(1) {
          top: 112px;
          left: 20px;
        }
        &:nth-child(2) {
          top: 60px;
          left: 270px;
        }
        &:nth-child(3) {
          top: 112px;
          left: 500px;
        }
        &:nth-child(4) {
          top: 300px;
          left: 10px;
        }
        &:nth-child(5) {
          top: 300px;
          right: 10px;
        }
      }
    }
    .modern_m_period_ii {
      width: fit-content;
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      padding-bottom: 50px;
      margin: 0 auto;
      &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background-color: #1E61FF;
        border-radius: 100%;
        margin-right: 12px;
        margin-top: -12px;
      }
    }
  }

  .index_subhead {
    text-align: center;
    font-size: 26px;
    font-weight: 400;
    color: #333;
    margin: 23px auto;
  }
  .index_more {
    width: 175px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    border: 2px solid #999999;
    font-size: 22px;
    color: #666;
    text-align: center;
    margin: 20px auto 0;
  }
  .serve_m_box {
    background-color: #fff;
    padding: 50px 37px;
    .serve_m_type {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 0 60px;
      .serve_m_type_item {
        width: fit-content;
        padding: 21px 30px;
        background: #FFFFFF;
        border-radius: 10px;
        border: 1px solid #BFBFBF;
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      .serve_m_type_isItem {
        color: #fff;
        border: 1px solid #2D43F2;
        background: #2D43F2;
      }
    }
  }
  .serve_m_hospital {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 41px;
    .serve_m_hospital_item {
      width: 48%;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 3px 16px 1px rgba(0,14,52,0.06);
      border-radius: 4px;
      border: 2px solid #E6E6E6;
      margin-bottom: 28px;
      padding: 21px;
      .serve_m_hospital_img {
        width: 138px;
        height: 138px;
      }
      .serve_m_hospital_name {
        font-size: 24px;
        font-weight: 400;
        color: #333333;
        padding-top: 20px;
      }
    }
  }
</style>
