<template>
  <div class="content-box" v-scroll-reveal>
    <div class="plan-title">
      <div class="flex title2">
        <img class="titleIcon1" src="../assets/img/titleIcon1.png" alt="" />
        <span>{{ implementationData.title }}</span>
        <img
          class="titleIcon1 rotate180"
          src="../assets/img/titleIcon1.png"
          alt=""
        />
      </div>
      <div class="flex mes2">
        <img src="../assets/img/titleIcon2.png" alt="" />
      </div>
    </div>
    <div
      class="plan-introduce"
      style="text-align: center"
      v-if="implementationData.introduce"
    >
      {{ implementationData.introduce }}
    </div>
    <div class="plan-box">
      <div
        class="flex flexS plan-item"
        v-for="(item, index) in implementationData.list"
        :key="index"
      >
        <img class="plan-icon" :src="item.img" alt="" />
        <div class="textConDiv">
          <div
            class="plan-name"
            :style="{ fontWeight: implementationData.fontWeight }"
          >
            {{ item.name }}
          </div>
          <div class="plan-item-introduce">{{ item.introduce }}</div>
        </div>
      </div>
    </div>
    <div
      class="flex plan-bnt"
      v-if="implementationData.isBnt"
      @click="openFun(true)"
    >
      了解详情
    </div>
  </div>
</template>

<script>
export default {
  name: "implementationMod",
  props: ["implementationData","open"],
  data() {
    return {
    };
  },
  methods: {
    openFun(bo){
      this.$emit('relationUs',true)
    }
  },
};
</script>

<style lang="scss" scoped>
.content-box {
  width: 1200px;
  margin: 0 auto;
}
.plan-title {
  margin-top: 76px;
}

.plan-introduce {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  line-height: 30px;
  margin-bottom: 30px;
}
.plan-box {
  display: flex;
  flex-wrap: wrap;
  .plan-item {
    width: 550px;
    margin-right: 100px;
    margin-bottom: 70px;
    .plan-icon {
      width: 75px;
      height: 75px;
      margin-right: 28px;
      display: inline-block;
    }
    .plan-name {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 16px;
    }
    .plan-item-introduce {
      font-size: 16px;
      font-weight: 400;
      color: #707070;
    }
  }
  .plan-item:nth-child(2n) {
    margin-right: 0;
  }
}

.plan-bnt {
  width: 185px;
  height: 50px;
  text-align: center;
  background: #2d43f2;
  border-radius: 25px;
  color: #fff;
  margin: 41px auto;
  cursor: pointer;
}
</style>